/* eslint-disable react/jsx-no-target-blank */
import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import { Link, withRouter } from "react-router-dom"
import logo from "../../assets/logo.svg"

const spacing = 8

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    justifyContent: "space-between",
    maxWidth: "1000px",
    margin: "50px auto 20px auto",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
      alignItems: "center",
    },
  },
  logoContainer: {
    display: "flex",
    flex: "1",
    alignItems: "center",
  },
  navLinkContainer: {
    display: "flex",
    flex: "1",
    justifyContent: "space-around",
    alignItems: "center",
    width: "100%",
  },
  navLink: {
    color: "white",
    textDecoration: "none",
    fontSize: "20px",
    fontWeight: "900",
    alignItems: "center",
    marginLeft: theme.spacing(3),
    "&:hover": {
      textDecoration: "underline ",
    },
  },
  name: {
    color: "white",
    fontSize: "20px",
    fontWeight: "900",
  },
  img: {
    width: theme.spacing(spacing),
    height: theme.spacing(spacing),
    marginRight: theme.spacing(1),
  },
  active: {
    color: "white",
    textDecoration: "underline",
    fontSize: "20px",
    fontWeight: "900",
    marginLeft: theme.spacing(3),
    "&:hover": {
      textDecoration: "underline ",
    },
  },
}))

const NavLink = withRouter(
  ({
    index,
    title,
    to,
    history: {
      location: { pathname },
    },
    onClick,
  }) => {
    const classes = useStyles()
    return (
      <h1>
        <Link
          to={to}
          className={pathname === to ? classes.active : classes.navLink}>
          {title}
        </Link>
      </h1>
    )
  }
)

function Nav({
  history: {
    location: { pathname },
  },
}) {
  const classes = useStyles()

  return (
    <nav className={classes.container}>
      <div className={classes.logoContainer}>
        <img alt="Logo" src={logo} className={classes.img} />
        <h1 className={classes.name}>DAVID OSEMWEGIE</h1>
      </div>
      <div className={classes.navLinkContainer}>
        <NavLink to="/" title="Home" />
        <NavLink to="/projects" title="Projects" />
        <a
          className={classes.navLink}
          href="https://www.dropbox.com/s/ellu1orp4d4ojq2/David__Osemwegie_-_Full_Stack_Software_Engineer%20%285%29.pdf?dl=0"
          target="_blank">
          Resume
        </a>
      </div>
    </nav>
  )
}

export default withRouter(Nav)
