import React from "react"
import { withRouter } from "react-router-dom"
import { makeStyles } from "@material-ui/core/styles"
import ReactPlayer from "react-player"

const spacing = 70

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  left: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  right: {
    flex: 1,
  },
  title: {
    color: "white",
    fontWeight: "900",
    fontSize: "40px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "30px",
    },
  },
  role: {
    fontWeight: "700",
    fontSize: "20px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "15px",
    },
  },
  description: {
    // margin: "50px 0",
    fontSize: "22px",
    fontWeight: "700",
  },
  button: {
    border: "solid 1px white",
    color: "white",
    padding: "5px 0",
    background: "none",
    fontSize: "20px",
    fontWeight: "700",
    maxWidth: "200px",
    marginBottom: "20px",
    "&:hover": {
      backgroundColor: "white",
      color: "black",
    },
  },
  img: {
    width: theme.spacing(spacing),
    background: "cover",
    [theme.breakpoints.down("sm")]: {
      width: theme.spacing(40),
      margin: "0 auto",
    },
  },
  tag: {
    background: `linear-gradient(180deg,rgba(255,255,255,0) 50%, ${theme.palette.secondary.main} 50%)`,
    marginRight: "20px",
    fontWeight: "900",
    fontSize: "25px",
    color: "white",
    [theme.breakpoints.down("sm")]: {
      fontSize: "20px",
    },
  },
}))

const Tag = ({ title }) => {
  const classes = useStyles()
  return (
    <div
      // style={{
      //   marginRight: "20px",
      //   backgroundColor: "white",
      //   borderRadius: "10px",
      //   padding: "5px",
      // }}
      className={classes.tag}>
      <span>{title}</span>
    </div>
  )
}

function ProjectDetails({
  location: {
    state: {
      projectData: {
        title,
        id,
        role,
        github,
        description,
        imgSrc,
        link,
        tags,
        videoLink,
      },
    },
  },
}) {
  const classes = useStyles()
  return (
    <div className={classes.container}>
      <div className={classes.left}>
        <h1 className={classes.title}>{title}</h1>
        <h2 className={classes.role}>{role}</h2>
        <p className={classes.description}>{description}</p>
        <div
          style={{ display: "flex", marginBottom: "20px", flexWrap: "wrap" }}>
          {tags.map((tag) => (
            <Tag title={tag} />
          ))}
        </div>
        {link ? (
          <button
            className={classes.button}
            onClick={() => {
              window.open(link)
            }}>
            VISIT SITE
          </button>
        ) : null}

        {github ? (
          <button
            className={classes.button}
            onClick={() => {
              window.open(github)
            }}>
            GITHUB REPO
          </button>
        ) : null}
      </div>
      <div className={classes.right}>
        {imgSrc ? (
          <img
            className={classes.img}
            alt="Screenshot of project"
            src={imgSrc}
          />
        ) : (
          <ReactPlayer url={`${videoLink}`} />
        )}
      </div>
    </div>
  )
}

export default withRouter(ProjectDetails)
