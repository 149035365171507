import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import { Link } from "react-router-dom"

const ProjectData = [
  {
    id: 1,
    title: "TracFlo",
    role: "Lead Full Stack Developer",
    link: "https://www.google.com/",
    github: "https://www.google.com/",
    tags: ["React", "Node.js", "Typescript", "REST", "Postgres", "AWS"],
    description:
      "Lead front and backend development teams the rebuilding of the company's core product.",
    imgSrc: "https://i.ibb.co/KxfG1VR/image.png",
  },
  {
    id: 2,
    title: "ATB Open Finance Hackathon",
    role: "2nd Place Winner",
    link: "https://www.clearjar.ca/",
    tags: ["React", "Node.js", "React-Native"],
    description:
      "Built mobile application that allows parents to monitor how their children are spending money.",
    imgSrc: "https://i.ibb.co/F5zY0mF/image.png",
  },
  {
    id: 3,
    title: "Stuff To Do App",
    role: "Freelance Project",
    link: "https://apps.apple.com/ca/app/stuff-to-do/id1497420436",
    github: "https://github.com/davidosemwegie/planapp/tree/master/plan",
    tags: ["React-Native", "Firebase"],
    description:
      "Build simple To-do list app with beautiful and simple UI and uploaded app to the app store.",
    imgSrc: "https://i.ibb.co/Cnf6BhS/image.png",
  },
  {
    id: 4,
    title: "Grubite",
    role: "Honours Project",
    link: "https://vimeo.com/405308821",
    github: "https://github.com/davidosemwegie/grubite",
    tags: ["React-Native", "Firebase"],
    description:
      "Build simple To-do list app with beautiful and simple UI and uploaded app to the app store.",
    //
    videoLink: "https://vimeo.com/405308821",
  },
]

const useStyles = makeStyles((theme) => ({
  title: {
    color: "white",
    fontWeight: "900",
    fontSize: "40px",
  },
  projectId: {
    color: theme.palette.secondary.main,
    marginRight: "10px",
    fontWeight: "900",
    fontSize: "40px",
  },
  highlight_large: {
    color: "white",
    fontWeight: "900",
    fontSize: "35px",
    background: `linear-gradient(180deg,rgba(255,255,255,0) 50%, ${theme.palette.secondary.main} 50%)`,
    "&:hover": {
      background: `linear-gradient(180deg,rgba(255,255,255,0) 0%, ${theme.palette.secondary.main} 50%)`,
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "20px",
    },
  },
}))

function ProjectItem({
  id,
  title,
  role,
  link,
  github,
  tags,
  imgSrc,
  description,
  videoLink,
}) {
  const classes = useStyles()
  return (
    <h2 style={{ marginBottom: "20px" }}>
      <Link
        style={{ textDecoration: "none" }}
        to={{
          pathname: `/projects/${title}`,
          state: {
            projectData: {
              id,
              title,
              role,
              link,
              github,
              tags,
              imgSrc,
              description,
              videoLink,
            },
          },
        }}>
        <span className={classes.projectId}>0{id}</span>
        <span className={classes.title}>{title}</span>
      </Link>
    </h2>
  )
}

function Projects() {
  return (
    <div style={{ display: "block" }}>
      {ProjectData.map((project) => (
        <ProjectItem
          key={project.id}
          id={project.id}
          title={project.title}
          role={project.role}
          link={project.link}
          github={project.github}
          tags={project.tags}
          imgSrc={project.imgSrc}
          description={project.description}
          videoLink={project.videoLink}
        />
      ))}
    </div>
  )
}

export default Projects
