import React from "react"
import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles((theme) => ({
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    marginRight: "10px",
    [theme.breakpoints.down("sm")]: {
      width: theme.spacing(2),
      height: theme.spacing(2),
    },
  },
  label: {
    display: "flex",
    flexDirection: "row",
    color: "white",
    fontWeight: "900",
    fontSize: "25px",
    marginBottom: "20px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "15px",
    },
  },
}))

function SocialElement({ title, img, href }) {
  const classes = useStyles()
  return (
    // eslint-disable-next-line react/jsx-no-target-blank
    <a className={classes.label} href={href} target="_blank">
      <img alt="Github" src={img} className={classes.small} />
      {title}
    </a>
  )
}

export default SocialElement
