import { createMuiTheme } from "@material-ui/core/styles"

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#AEC6CF",
    },
    secondary: {
      main: "#97B2BC",
    },
  },
})

export default theme
