import React from "react"
import "./App.css"
import { BrowserRouter as Router, Switch, Route } from "react-router-dom"
import HomeScreen from "./components/HomeScreen"
import Projects from "./components/Projects"
import Nav from "./components/Nav"

import ProjectDetails from "./components/Projects/ProjectDetails"

// import { makeStyles } from "@material-ui/core/styles"

// const useStyles = makeStyles((theme) => ({
//   highlight_large: {
//     color: "white",
//     fontWeight: "900",
//     fontSize: "35px",
//     background: `linear-gradient(180deg,rgba(255,255,255,0) 50%, ${theme.palette.secondary.main} 50%)`,
//     "&:hover": {
//       background: `linear-gradient(180deg,rgba(255,255,255,0) 0%, ${theme.palette.secondary.main} 50%)`,
//     },
//     [theme.breakpoints.down("sm")]: {
//       fontSize: "20px",
//     },
//   },
// }))

// function Working() {
//   const classes = useStyles()
//   return (
//     <h1 style={{ textAlign: "center" }}>
//       <span className={classes.highlight_large}>
//         This Page is still under construction...
//       </span>
//       &#128679;
//     </h1>
//   )
// }

function App(props) {
  return (
    <div style={{ flex: 2 }}>
      <Router>
        <Nav />
        <div
          style={{
            maxWidth: "1000px",
            margin: "80px auto 0 auto",
          }}>
          <Switch>
            <Route exact path="/">
              <HomeScreen />
            </Route>
            <Route exact path="/projects">
              <Projects />
            </Route>
            <Route exact path="/projects/:projectId">
              <ProjectDetails />
            </Route>
          </Switch>
        </div>
      </Router>
    </div>
  )
}

export default App
