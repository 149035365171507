import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import { SocialElement } from "../common"
import github from "../../assets/github.svg"
import instagram from "../../assets/instagram.svg"
import linkedIn from "../../assets/linkedin.svg"
import { Link } from "react-router-dom"

const useStyles = makeStyles((theme) => ({
  text: {
    color: "white",
  },
  sentence: {
    marginBottom: "20px",
  },
  highlight_large: {
    color: "white",
    fontWeight: "900",
    fontSize: "40px",
    background: `linear-gradient(180deg,rgba(255,255,255,0) 50%, ${theme.palette.secondary.main} 50%)`,
    "&:hover": {
      background: `linear-gradient(180deg,rgba(255,255,255,0) 0%, ${theme.palette.secondary.main} 50%)`,
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "20px",
    },
  },
  highlight: {
    background: `linear-gradient(180deg,rgba(255,255,255,0) 50%, ${theme.palette.secondary.main} 50%)`,
    fontWeight: "900",
    fontSize: "30px",
    color: "white",
    [theme.breakpoints.down("sm")]: {
      fontSize: "20px",
    },
  },
  container: {
    // backgroundColor: "red",
  },
  page: {
    maxWidth: "1000px",
    margin: "0 auto",
  },
  contactContainer: {
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      display: "block",
    },
  },
  h2: {
    fontSize: "30px",
    marginBottom: "30px",
  },
}))

function HomeScreen({ inProgress }) {
  const classes = useStyles()
  return (
    <div className={classes.page}>
      <div className={classes.container}>
        <h2 className={classes.h2}>
          Hey, I'm a{" "}
          <span className={classes.highlight_large}>Full Stack Developer</span>
        </h2>
        <h2 className={classes.h2}>
          That specializes in{" "}
          <span className={classes.highlight}>JavaScript/Node.js</span>
        </h2>
        <h2 className={classes.h2}>
          & <span className={classes.highlight}>React / React Native</span>
        </h2>
        <h2 className={classes.h2}>
          Click here to have peek at a few{" "}
          <Link to="/projects">
            <span className={classes.highlight}>projects</span>
          </Link>{" "}
          I have worked on
        </h2>
        <div className={classes.contactContainer}>
          <div
            style={{
              flex: 1,
            }}>
            <h2 className={classes.h2}>Heres how to find me</h2>
            <SocialElement
              title="Github"
              img={github}
              href="https://github.com/davidosemwegie"
            />
            <SocialElement
              title="LinkedIn"
              img={instagram}
              href="https://www.linkedin.com/in/david-osemwegie-299314112/"
            />
            <SocialElement
              title="Instagram"
              img={linkedIn}
              href="https://www.instagram.com/osazi/"
            />
          </div>
          <div
            style={{
              flex: 1,
            }}>
            <h2 className={classes.h2}>Lets chat</h2>
            <a className={classes.highlight} href="mailto:dosemwegie@gmail.com">
              dosemwegie@gmail.com
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default HomeScreen
